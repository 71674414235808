import React from "react";
import { ReactComponent as SearchIcon } from "../../assets/icons/search-icon.svg";
import "./FilterComponent.css";
import FilterBar from "./FilterBar"; // Import FilterBar instead of SearchBar
import { useState, useEffect } from "react";
import Checkbox from "../CheckBox/CheckBox";

const FilterComponent = () => {
  const [dramaNames, setDramaNames] = useState([]);
  const [filterTerm, setFilterTerm] = useState("");

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/scenes/dramaNames`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => setDramaNames(data))
      .catch((error) => console.error("Error:", error));
  }, []);
  // filter the drama names
  const filteredDramaNames = dramaNames.filter((drama) =>
    drama.toLowerCase().includes(filterTerm.toLowerCase()),
  );

  return (
    <div className="filter-main-container">
      <div className="search">
        <FilterBar
          placeholder={"Enter Drama Name..."}
          className="filter-bar"
          onChange={setFilterTerm}
        />
      </div>
      <div className="hr-under-search"></div>
      <div className="drama-options">
        <ul className="list-unstyled">
          {filteredDramaNames.map((drama, index) => (
            <li key={drama}>
              <Checkbox id={`dramaCheckbox${index}`} label={drama} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default React.memo(FilterComponent);
