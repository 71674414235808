import React, { useReducer, createContext } from "react";

// Initial state
const initialState = {
  isTranslationHidden: false,
  playbackRate: 1.0,
  contextScenes: [],
  isContextMode: false,
};

// Create context
export const CardOptionsContext = createContext(initialState);

// Reducer function
function cardOptionsReducer(state, action) {
  switch (action.type) {
    case "setIsTranslationHidden":
      return { ...state, isTranslationHidden: action.value };
    case "setPlaybackRate":
      return { ...state, playbackRate: action.value };
    case "setContextScenes":
      return { ...state, contextScenes: action.value };
    case "setIsContextMode":
      return { ...state, isContextMode: action.value };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
}

// Provider component
export const CardOptionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cardOptionsReducer, initialState);

  return (
    <CardOptionsContext.Provider value={{ state, dispatch }}>
      {children}
    </CardOptionsContext.Provider>
  );
};
