import React, { useState } from "react";
import "./SearchBar.css"; // make sure to create this CSS file
import { ReactComponent as SearchIcon } from "../../assets/icons/search-icon.svg";

const SearchBar = ({ placeholder, fetchSearchResults }) => {
  const [searchPhrase, setSearchPhrase] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    fetchSearchResults(searchPhrase);
  };

  return (
    <form
      action="/search"
      method="get"
      className="search-form"
      onSubmit={handleSubmit}
    >
      <input
        id="searchPhrase"
        className="searchbar"
        type="search"
        name="searchPhrase"
        placeholder={placeholder}
        value={searchPhrase}
        onChange={(e) => setSearchPhrase(e.target.value)}
        required
      />

      <SearchIcon className="search-icon" onClick={handleSubmit}></SearchIcon>
    </form>
  );
};

export default SearchBar;
