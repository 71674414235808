import React, { useContext } from "react";
import { CardOptionsContext } from "../Contexts/CardOptionsContext";
import "./PlaybackSpeedControl.css";

const PlaybackSpeedControl = () => {
  const { state, dispatch } = useContext(CardOptionsContext);
  const speeds = [0.25, 0.5, 0.75, 1.0];
  const currentSpeedIndex = speeds.indexOf(state.playbackRate);

  const handleClick = () => {
    const newIndex = (currentSpeedIndex + 1) % speeds.length;
    dispatch({ type: "setPlaybackRate", value: speeds[newIndex] });
  };

  return (
    <div
      className={`playback-speed-control ${
        state.playbackRate !== 0.25 ? "active" : ""
      }`}
      onClick={handleClick}
    >
      <span className="playback-speed-control-text">
        {state.playbackRate.toFixed(2)}x
      </span>
    </div>
  );
};

export default PlaybackSpeedControl;
