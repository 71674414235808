import { BrowserRouter as Router } from "react-router-dom";
import AuthRoutes from "./components/AuthRoutes/AuthRoutes";
import "./App.css";
import { CardOptionsProvider } from "./components/Contexts/CardOptionsContext";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <CardOptionsProvider>
      <Router>
        <AuthRoutes />
      </Router>
    </CardOptionsProvider>
  );
}

export default App;
