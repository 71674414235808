import { useState, useEffect } from "react";
import { Route, useNavigate, Routes } from "react-router-dom";
import LoginPage from "../LoginPage/LoginPage";
import MainPage from "../MainPage/MainPage";

const BASE_URL = process.env.REACT_APP_BASE_URL;

function AuthRoutes() {
  const [scenes, setScenes] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  const fetchSearchResults = (searchPhrase) => {
    fetch(
      `${BASE_URL}/scenes/search?query=${encodeURIComponent(searchPhrase)}`,
      {
        credentials: "include",
      },
    )
      .then((response) => response.json())
      .then((data) => {
        setScenes(data);
      })
      .catch((error) => console.error("Error:", error));
  };

  // This function fetches from the /auth/check endpoint to check if the user is authenticated
  const checkAuthStatus = () => {
    fetch(`${BASE_URL}/auth/check`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  useEffect(() => {
    checkAuthStatus();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/searchpage");
    } else {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  return (
    <Routes>
      <Route
        path="/"
        element={<LoginPage onLoginAttempt={checkAuthStatus} />}
      />
      <Route
        path="/searchpage"
        element={
          <MainPage scenes={scenes} fetchSearchResults={fetchSearchResults} />
        }
      />
    </Routes>
  );
}

export default AuthRoutes;
