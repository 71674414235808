import { useEffect, useState } from "react";
import "./LoginPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGoogle,
  faFacebookF,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { Toast } from "react-bootstrap";

function LoginPage() {
  let [showToast, setShowToast] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("error")) {
      // Handle the error from the Google OAuth process
    }
  }, []);

  const redirectToGoogleAuth = (event) => {
    //need to
    event.preventDefault();

    window.location = `${process.env.REACT_APP_BASE_URL}/auth/google`;
  };

  let [authMode, setAuthMode] = useState("signin");

  const changeAuthMode = () => {
    setAuthMode(authMode === "signin" ? "signup" : "signin");
    if (authMode === "signin") {
      setShowToast(true);
    }
  };

  if (authMode === "signin") {
    return (
      <div className="Auth-form-container">
        <form className="Auth-form">
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Sign In</h3>
            <div className="text-center color-softer">
              Don't have an account?{" "}
              <span
                className="link-primary link cursor-pointer"
                onClick={changeAuthMode}
              >
                Sign Up
              </span>
            </div>
            <div className="form-group mt-3">
              <label>Email address</label>
              <input
                type="email"
                className="form-control mt-1  no-margin-top"
                placeholder="Enter email"
              />
            </div>
            <div className="form-group mt-3">
              <label>Password</label>
              <input
                type="password"
                className="form-control mt-1 no-margin-top"
                placeholder="Enter password"
              />
            </div>
            <p className="text-left mt-2 forgotlbl">
              <span className="link-primary link forgotlink cursor-pointer">
                {" "}
                Forgot Password?
              </span>
            </p>
            <div className="d-grid gap-2 mt-3 d-flex justify-content-center">
              <button type="submit" className="btn btn-primary btn-sm loginbtn">
                <p className="submittxt "> Sign In</p>
              </button>
            </div>

            <div className="or-separator">
              <span>OR</span>
            </div>

            <div className="custom-btns">
              <button
                className="custom-btn google-btn"
                onClick={redirectToGoogleAuth}
              >
                <FontAwesomeIcon icon={faGoogle} className="icon" />
              </button>
              <button className="custom-btn facebook-btn">
                <FontAwesomeIcon icon={faFacebookF} className="icon" />
              </button>
              <button className="custom-btn linkedin-btn">
                <FontAwesomeIcon icon={faLinkedinIn} className="icon" />
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }

  return (
    <div className="Auth-form-container">
      <Toast
        className="toast-bottom-center"
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
      >
        <Toast.Body>Coming Soon :)</Toast.Body>
      </Toast>
      <form className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign In</h3>
          <div className="text-center">
            Already registered?{" "}
            <span className="link-primary" onClick={changeAuthMode}>
              Sign In
            </span>
          </div>
        </div>
      </form>
    </div>
  );
}

export default LoginPage;
