import Navbar from "../Navbar/Navbar";
import SearchPage from "../SearchPage/SearchPage";
import "./MainPage.css";

function MainPage({ scenes, fetchSearchResults }) {
  return (
    <>
      <Navbar className="Navbar" fetchSearchResults={fetchSearchResults} />
      <SearchPage className="SearchPage" scenes={scenes} />
    </>
  );
}

export default MainPage;
