import SceneCard from "../SceneCard/SceneCard";
import CardOptionsComponent from "../CardOptionsComponent/CardOptionsComponent";
import React, { useState, useContext } from "react";
import { useEffect } from "react";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import FilterComponent from "../FilterComponent/FilterComponent";
import { CardOptionsContext } from "../Contexts/CardOptionsContext";
import "./SearchPage.css";
import PlaybackSpeedControl from "../PlaybackSpeedControl/PlaybackSpeedControl";

const SearchPage = ({ scenes }) => {
  const [contextScenes, setContextScenes] = useState([]);
  const [isContextMode, setIsContextMode] = useState(false);

  function handleGetContext(contextScenes) {
    setContextScenes(contextScenes);
    setIsContextMode(true);
  }

  useEffect(() => {
    // Reset the context mode whenever there's a new search
    setIsContextMode(false);
  }, [scenes]);

  const SearchMode = ({ scenes, handleGetContext }) => {
    const { state } = useContext(CardOptionsContext);

    return (
      <div className="search_mode search_page">
        <div className="sidebar">
          <CardOptionsComponent />
          <FilterComponent />
        </div>
        <div className="scenes">
          {scenes.map((scene) => (
            <SceneCard
              key={scene._id}
              scene={scene}
              onGetContextScenes={handleGetContext}
              isTranslationHidden={state.isTranslationHidden}
              playbackRate={state.playbackRate}
            />
          ))}
        </div>
      </div>
    );
  };

  const ContextMode = ({ contextScenes, setIsContextMode }) => {
    const { state } = useContext(CardOptionsContext);

    return (
      <div className="context_mode">
        <div className="context_mode_options">
          <button
            className="optionsearchpage text-options backbtn"
            onClick={() => setIsContextMode(false)}
          >
            &lt; Back to search results
          </button>
          <div className="option-container-inner">
            <div className="optionsearchpage text-options">
              Hide Translation
              <ToggleSwitch />
            </div>
            <div className="optionsearchpage text-options">
              Playback Speed
              <PlaybackSpeedControl />
            </div>
          </div>
        </div>
        <div className="sidebar"></div>
        <div className="context-scenes">
          {contextScenes.map((scene) => (
            <SceneCard
              key={scene._id}
              scene={scene}
              isTranslationHidden={state.isTranslationHidden}
              playbackRate={state.playbackRate}
            />
          ))}
        </div>
      </div>
    );
  };

  return isContextMode ? (
    <ContextMode
      contextScenes={contextScenes}
      setIsContextMode={setIsContextMode}
    />
  ) : (
    <SearchMode scenes={scenes} handleGetContext={handleGetContext} />
  );
};
export default SearchPage;
