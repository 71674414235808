import React from "react";
import "./Navbar.css";
import { ReactComponent as DonateIcon } from "../../assets/icons/donate.svg";
import { ReactComponent as HamburgerIcon } from "../../assets/icons/hamburger.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/profile.svg";
import SearchBar from "../SearchBar/SearchBar";
import Logo from "../../assets/logo.png"; // import PNG logo

const Navbar = ({ fetchSearchResults }) => {
  return (
    <nav className="navbar">
      <div className="logo">
        <a href="/">
          <img src={Logo} alt="Logo" />
        </a>{" "}
        {/* Use img tag for PNG logo */}
      </div>

      <div className="search-icons-container">
        <div className="search-two">
          <SearchBar
            placeholder={"Enter a Korean word/phrase here"}
            fetchSearchResults={fetchSearchResults}
          ></SearchBar>
        </div>

        <div className="icons">
          <a href="/" className="nav-link">
            <DonateIcon />
          </a>
          <a href="/about" className="nav-link">
            <HamburgerIcon />
          </a>
          <a href="/services" className="nav-link">
            <ProfileIcon />
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
