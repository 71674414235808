import React, { useState, useContext } from "react";
import { CardOptionsContext } from "../Contexts/CardOptionsContext";
import "./CardOptionsComponent.css";
import { ReactComponent as SortAscIcon } from "../../assets/icons/sort-asc.svg";
import { ReactComponent as SortDscIcon } from "../../assets/icons/sort-desc.svg";
import { ReactComponent as TranslationBlurIcon } from "../../assets/icons/translation-blur-icon.svg";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import PlaybackSpeedControl from "../PlaybackSpeedControl/PlaybackSpeedControl";

const CardOptions = () => {
  const { state, dispatch } = useContext(CardOptionsContext);
  const [sortOrder, setSortOrder] = useState("asc"); // Default sort order

  const sortCards = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc"); // Toggle sort order for next sort
  };

  return (
    <div className="options">
      <div className="option">
        <div className="sort">Sort By</div>
        {sortOrder === "asc" ? (
          <SortAscIcon className="sort-icon" onClick={sortCards} />
        ) : (
          <SortDscIcon className="sort-icon" onClick={sortCards} />
        )}
      </div>
      <div className="option translation-blur">
        Hide Translation
        <ToggleSwitch />
      </div>
      <div className="option playback-speed">
        Playback Speed
        <PlaybackSpeedControl />
      </div>
    </div>
  );
};

export default CardOptions;
