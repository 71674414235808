import React from "react";
import "./Checkbox.css";
function Checkbox({ id, label }) {
  return (
    <label htmlFor={id} className="checkbox">
      <input className="checkbox__input" type="checkbox" id={id} />
      <svg
        className="checkbox__icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
      >
        <rect
          width="21"
          height="21"
          x=".5"
          y=".5"
          fill="transparent"
          stroke="#000"
          strokeWidth="2"
          rx="2"
        />{" "}
        <path
          className="tick"
          stroke="#6EA340"
          fill="none"
          strokeLinecap="round"
          strokeWidth="4"
          d="M4 10l5 5 9-9"
        />
      </svg>
      <span className="checkbox__label">{label}</span>
    </label>
  );
}

export default Checkbox;
