import React, { useContext } from "react";
import { CardOptionsContext } from "../Contexts/CardOptionsContext";
import "./ToggleSwitch.css";

const ToggleSwitch = () => {
  const { state, dispatch } = useContext(CardOptionsContext);

  const handleChange = () => {
    dispatch({
      type: "setIsTranslationHidden",
      value: !state.isTranslationHidden,
    });
  };

  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={state.isTranslationHidden}
        onChange={handleChange}
      />
      <span className="slider round"></span>
    </label>
  );
};

export default ToggleSwitch;
