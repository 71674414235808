// src/components/SceneCard.js
import React, { useRef, useEffect, useState } from "react";
import { ReactComponent as PlayIcon } from "../../assets/icons/play-icon.svg";
import { ReactComponent as OfficialTranslationIcon } from "../../assets/icons/official-translation-icon.svg";
import { ReactComponent as AddFavIcon } from "../../assets/icons/add-to-favourites.svg";
import { ReactComponent as OnlineTranslationIcon } from "../../assets/icons/online-translation-icon.svg";
import { ReactComponent as ContextIcon } from "../../assets/icons/context-icon.svg";
import { ReactComponent as RemoveFavIcon } from "../../assets/icons/remove-from-favourites.svg";
import "./SceneCard.css";

const SceneCard = ({
  scene,
  isTranslationHidden,
  playbackRate,
  onGetContextScenes,
}) => {
  const audioRef = useRef();
  const [isFavourite, setIsFavourite] = useState(false);

  const handlePlayAudio = () => {
    const audio = audioRef.current;
    if (audio) audio.play();
  };

  const setFavouriteScene = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/scenes/favourite`;
    const newIsFavourite = !isFavourite;
    setIsFavourite(newIsFavourite);

    fetch(url, {
      method: newIsFavourite ? "POST" : "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ sceneId: scene._id }),
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
        } else {
          console.error(`Error ${response.status}: ${response.statusText}`);
        }
      })
      .catch((error) => console.error("Error:", error));
  };
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = playbackRate;
    }
  }, [playbackRate]);

  const getContext = () => {
    //only if nextscenes or previousscenes is not empty and not undefined!
    if (!previous_scenes || !next_scenes) {
      return;
    }
    if (previous_scenes.length === 0 && next_scenes.length === 0) {
      return;
    }

    const allScenes = previous_scenes.concat(_id).concat(next_scenes);
    const url = `${
      process.env.REACT_APP_BASE_URL
    }/scenes/context?ids=${JSON.stringify(allScenes)}`;
    fetch(url, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        displayContext(data);
      })
      .catch((error) => console.error("Error:", error));
  };

  const displayContext = (data) => {
    onGetContextScenes(data);
  };
  const {
    _id,
    subtitle,
    image_path,
    audio_path,
    drama_name,
    episode,
    season,
    timestamp,
    official_translation,
    online_translation,
    previous_scenes,
    next_scenes,
  } = scene;

  const subtitleRef = useRef(); // reference to the subtitle container
  const [imageHeight, setImageHeight] = useState("auto"); // state variable to store the height of the image

  useEffect(() => {
    function handleResize() {
      const subtitleHeight = subtitleRef.current.offsetHeight;
      const screenHeight = window.innerHeight;
      const x = 1000; // Replace this with your actual threshold

      if (screenHeight < x) {
        document.documentElement.style.setProperty(
          "--img-height",
          `${subtitleHeight}px`,
        );
        //console.log(subtitleHeight)
      } else {
        document.documentElement.style.setProperty("--img-height", "20vh");
      }
    }

    // Call once to set the initial state
    handleResize();

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Append the audio and image paths to the Linode base URL
  return (
    <div className="scene-card">
      {/* Use the full image path */}
      <div className="scene-info">
        <div
          className="drama-name-container"
          style={{
            background: isFavourite
              ? "rgba(184, 224, 210, 0.58)"
              : "rgba(234, 196, 213, 0.50)",
          }}
        >
          {" "}
          <h2 className="drama-name">
            {drama_name} - S{season}E{episode}
          </h2>
          <div
            className="drama-name-icon-container"
            onClick={setFavouriteScene}
          >
            {isFavourite ? <RemoveFavIcon /> : <AddFavIcon />}
          </div>
        </div>
      </div>

      <div className="main-card">
        <div className="img-container">
          <img src={image_path} alt={drama_name} />{" "}
        </div>

        <div className="subtitle-container" ref={subtitleRef}>
          <div className="card-subtext-container">
            <PlayIcon onClick={handlePlayAudio} />
            <h3 className="card-subtext">{subtitle}</h3>
          </div>
          <div
            className={`card-subtext-container ${
              isTranslationHidden ? "hidden-translation" : ""
            }`}
          >
            <OfficialTranslationIcon />
            <p className="card-subtext translation">{official_translation}</p>
          </div>
          <div
            className={`card-subtext-container ${
              isTranslationHidden ? "hidden-translation" : ""
            }`}
          >
            <OnlineTranslationIcon />
            <p className="card-subtext translation">{online_translation}</p>
            {previous_scenes}
          </div>
        </div>
        <ContextIcon className="context-icon" onClick={getContext} />
      </div>

      <audio ref={audioRef} src={audio_path}></audio>
    </div>
  );
};

export default SceneCard;
