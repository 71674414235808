import React, { useState } from "react";
import "./FilterBar.css"; // make sure to create this CSS file
import { ReactComponent as SearchIcon } from "../../assets/icons/search-icon.svg";

const FilterBar = ({ placeholder, onChange }) => {
  const [filterPhrase, setFilterPhrase] = useState("");

  const handleChange = (event) => {
    setFilterPhrase(event.target.value);
    if (onChange) {
      // Call the onChange function only if provided
      onChange(event.target.value);
    }
  };

  return (
    <div className="filter-form">
      <input
        id="filterPhrase"
        className="filterbar"
        type="search"
        name="filterPhrase"
        placeholder={placeholder}
        value={filterPhrase}
        onChange={handleChange}
        required
      />

      <SearchIcon className="search-icon" />
    </div>
  );
};

export default FilterBar;
